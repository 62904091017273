@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@400;500;800&family=Noto+Serif:wght@400;700&family=Playfair+Display:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
* {
    margin: 0;
    padding: 0;

}

body {
    font-family: 'M PLUS 1p', sans-serif;
    /* font-family: 'Noto Serif', serif; */

}
