
.main {
    width: 100%;
    height: 88vh;
}

video {
    width: 100%;
    height: 88vh;
    object-fit: cover;
}

.content {
    position: absolute;
    top: 60%;
    width: 100%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    
}

.overlay {
    position: absolute;
    top: 12%;
    left: 0;
    width: 100%;
    height: 88vh;
    background-color: rgba(0,0,0,0.6);
    
}



.cloud{ 
     margin-top:70px;
     width:350px;
     height:120px;
     background:#ECEFF1;
     box-shadow: 10px 10px rgba(0,0,0,0.2);
     border-radius:100px;
  }
  .cloud::after, .cloud::before{
      content:"";
      position:relative;
      display:inline-block;
      background:inherit;
      border-radius:inherit;
  }
  .cloud::after{
      width:100px;
      height:100px;
      top:-220px;
      left:50px;
  }
  .cloud::before{
      width:180px;
      height:180px;
      top: -70px;
      left:130px;
  }
  
  