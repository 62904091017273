.navBg {
    background: linear-gradient(276deg, rgba(238,174,76,1) 15%, rgba(0,140,69,1) 100%);
    height: 12vh;
}

.navFont {
  font-weight: 800;
  font-size: 0.9rem;
 
}

.navHover {
    color: rgb(255, 255, 255);
    text-decoration: none;
    padding-bottom: 5px;
    cursor: pointer;
  
  }

  .navHover {
    background:
      linear-gradient(
        to right,
        rgba(100, 200, 200, 0.1),
        rgba(100, 200, 200, 0.1)
      ),
      linear-gradient(
        to right,
        rgb(226, 138, 6),
        rgb(221, 100, 0)
        
    );
    background-size: 100% 3px, 0 3px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
  }
  
  .navHover:hover {
    background-size: 0 0px, 100% 4px;
  }
  
  .active{
   border-bottom: 4px solid white;

  }
